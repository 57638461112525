module.exports = {
  store: require(`./config.${process.env.GATSBY_ACTIVE_STORE}.js`),
  stores: {
    "the-neighbours-cellar": require("./config.the-neighbours-cellar.js"),
  },
  theme: {
    color: "#B58D81",
    background: "#CDB6FB",
    display: "minimal-ui",
    icon: "static/images/favicon.png",
  },
  settings: {
    personaliser: {
      placid: {
        createImageRestEndpoint: "https://api.placid.app/api/rest/",
        url: "https://api.placid.app/u/",
        media: "https://api.placid.app/api/rest/media",
        fileNamePrefix: "customiser-image_",
        textLayerPrimary: "Top Text",
        textLayerSecondary: "Bottom Text",
        imageLayerPrimary: "Photo Upload",
        elementLayerPrimary: "Elements",
        backgroundLayerPrimary: "Background",
        previewParameter: "noPreview",
      },
      bundle: {
        sanityImageNamePrefix: "bundle-final-image_",
        lineItemPropKeyBundleId: "_bundleId",
        lineItemPropKeyAlcohol: "_isAlcohol",
        lineItemPropKeyLabelImage: "_labelImageUrl",
        lineItemPropKeyLabelTitle: "_labelTitle",
        lineItemPropKeyAddOns: "_isAddon",
        lineItemPropKeySticker: "_isSticker",
        lineItemPropKeyStickerDate: "_date",
        lineItemPropKeyGiftcard: "_isGiftcard",
        lineItemPropKeyGiftcardMessage: "_message",
        lineItemPropKeyGiftBox: "_isGiftBox",
      },
    },
    product: {
      sizeOptionName: "Size",
      colourOptionName: "Color",
    },
    customer: {
      tagPrefix: "preferences",
    },
    keys: {
      market: "market",
      shopify: "shopify",
      country: "country",
      location: "location",
      checkout: "checkout",
      discounts: "discounts",
      customer: "customer",
      newsletter: "subscribe",
      announcement: "announcement",
      maintenance: "maintenance",
      password: "password",
      recent: "recent",
    },
    params: {
      checkout: "checkout_url",
      continue: "return",
      customer: "key",
      giftcard: "redeem",
      variant: "variant",
      preview: "preview",
    },
    routes: {
      HOMEPAGE: "/",
      FLEXIBLE: "",
      GENERIC: "",
      PAGE: "",
      ERROR: "/404",
      CART: "/cart",
      CONTACT: "/contact",
      SEARCH: "/search",
      PASSWORD: "/password",
      GIFTCARD: "/giftcard",
      LOGIN: "/account/login",
      REGISTER: "/account/register",
      FORGOT: "/account/forgot",
      RESET: "/account/reset",
      ACTIVATE: "/account/activate",
      INVALID: "/account/invalid_token",
      DASHBOARD: "/account",
      ORDERS: "/account/orders",
      DETAILS: "/account/details",
      ADDRESSES: "/account/addresses",
      PREFERENCES: "/account/preferences",
      PRODUCT: "/products",
      COLLECTION: "/collections",
    },
    defaultRedirects: [
      {
        source: "/personaliser",
        destination: "/build-your-own-gift-box",
        type: 301
      }
    ],
    clientPaths: ["/account/reset/*", "/account/activate/*", "/account/orders/*"],
    sitemapExclusions: [
      "/offline-plugin-app-shell-fallback",
      "/dev-404-page",
      "/404",
      "/404.html",
      "/password",
      "/preview",
      "/account",
      "/account/login",
      "/account/forgot",
      "/account/activate",
      "/account/reset",
      "/account/orders",
      "/account/addresses",
      "/account/preferences",
      "/search",
      "/cart",
    ],
  },
}

import { useCallback, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { useFunctions } from "@app/hooks/useFunctions"

const INITIAL_STATE = { email: "" }

type CustomerSubscribeResponse = {
  body: {
    emailMarketingConsent: {
      marketingState: string
    },
    id: string
  }
  status: "success" | "error"
}

export const useNewsletter = () => {
  const [data, setData] = useState(INITIAL_STATE)
  const [success, setSuccess] = useState(false)
  const { callFunction, errors, loading } = useFunctions()

  const { newsletter } = useStaticQuery<GatsbyTypes.StaticNewsletterQuery>(graphql`
    query StaticNewsletter {
      newsletter: sanitySettingNewsletter {
        title
        description
        emailPlaceholder
        successMessage
      }
    }
  `)

  const handleChange = useCallback(
    ({ target: { type, name, value, checked } }: React.ChangeEvent<HTMLInputElement>) => {
      setData(prevState => ({
        ...prevState,
        [name]: type === "checkbox" ? checked : value,
      }))
    },
    [setData]
  )

  const handleSubmit = useCallback(
    async (e: React.SyntheticEvent) => {
      e.preventDefault()
      const response = await callFunction<CustomerSubscribeResponse>("customer-subscribe", { ...data, emailMarketingConsent: { marketingState: 'SUBSCRIBED' } })

      setSuccess("success" === response.status)
      return { customer: response.body }
    },
    [callFunction, data, setSuccess]
  )

  return {
    data,
    errors,
    loading,
    success,
    newsletter,
    handleSubmit,
    handleChange,
  }
}

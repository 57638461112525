import React, { useState, useMemo, useCallback, useEffect } from "react"
import { useQuery, useMutation } from "@apollo/client"
import { useCore, useStorage } from "@app/hooks/useCore"
import { useShopify } from "@app/hooks/useShopify"
import { useFunctions } from "@app/hooks/useFunctions"
import { useConfigContext } from "@app/providers/config"
import { useCustomerContext } from "@app/providers/customer"
import { useAnalytics } from "@app/hooks/useAnalytics"
import { CheckoutFragment } from "@root/types/custom-types/Fragments/CheckoutFragment/CheckoutFragment"
import { NormalisedCheckout } from "@root/types/custom-types/Checkout/Checkout"
import { NormalisedCheckoutLineItem } from "@root/types/custom-types/Checkout/CheckoutLineItem"

type ContextProps = {
  id: string
  url: string
  count: number
  checkout: NormalisedCheckout | undefined
  loading: boolean
  countryCode: string
  refreshCheckout: () => void
  gotoCheckout: (event: MouseEvent) => void
  saveCheckout: (checkout: any) => void
  createCheckout: (countryCode: string | undefined, forceNew: boolean) => void
  bundleGroups: NormalisedCheckoutLineItem[][] | undefined
  nonBundleLineItems: NormalisedCheckoutLineItem[] | undefined
}

export const CheckoutContext = React.createContext<ContextProps | undefined>(undefined)

export const CheckoutProvider: React.FC = ({ children }) => {
  const {
    graphql: {
      mutations: { CHECKOUT_CREATE },
      queries: { GET_CHECKOUT },
    },
  } = useCore()
  const { getStorage, setStorage, removeStorage } = useStorage()
  const {
    store,
    settings: { keys },
  } = useConfigContext()
  const { decorateUrl } = useAnalytics()
  const { callFunction } = useFunctions()
  const { customer } = useCustomerContext()
  const { checkoutNormaliser } = useShopify()
  const { refetch: getCheckoutQuery } = useQuery(GET_CHECKOUT, { fetchPolicy: "no-cache", skip: true })
  const [checkoutCreate] = useMutation(CHECKOUT_CREATE)
  const [checkout, setCheckout] = useState<NormalisedCheckout>()
  const [loading, setLoading] = useState(false)

  const bundleGroups = useMemo(() => {
    if (!checkout) return

    const items = checkout.lineItems.reduce((acc, item) => {
      const bundleId = item.customAttributes.find(a => a.key === "_bundleId")?.value
      if (!bundleId) return [...acc, [item]]

      const bundle = acc.find(group => group[0].customAttributes.find(a => a.key === "_bundleId")?.value === bundleId)

      if (bundle) {
        bundle.push(item)
        return acc
      }

      return [...acc, [item]]
    }, [] as NormalisedCheckoutLineItem[][])

    const reversedItems = items.reverse()

    return reversedItems
  }, [checkout])

  const nonBundleLineItems = useMemo(() => {
    if (!bundleGroups) return checkout?.lineItems

    return checkout?.lineItems.filter(item => !item.customAttributes.find(a => a.key === "_bundleId"))
  }, [bundleGroups, checkout?.lineItems])

  const id = useMemo(() => checkout?.id || getStorage(keys.checkout), [getStorage, keys.checkout, checkout?.id])

  const url = useMemo(
    () => (checkout?.webUrl ? decorateUrl(checkout?.webUrl.replace(store.shopifyShopDomain, store.shopifyCheckoutUrl)) : ""),
    [checkout, store, decorateUrl]
  )

  const countryCode = useMemo(
    () => checkout?.buyerIdentity?.countryCode || getStorage(keys.market) || "AU",
    [getStorage, keys.market, checkout?.buyerIdentity?.countryCode]
  )

  const count = useMemo(() => {
    const standardCount =
      nonBundleLineItems?.reduce(
        (count: number, lineItem: NormalisedCheckoutLineItem, i: number) => (i ? count + lineItem.quantity : lineItem.quantity),
        0
      ) || 0
    const bundleCount = bundleGroups?.length || 0

    return standardCount + bundleCount
  }, [nonBundleLineItems, bundleGroups])

  useEffect(() => {
    createCheckout(store.locationRegion)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getCheckout = useCallback(async () => {
    try {
      if (id) {
        const countryCode = getStorage(keys.market) || store.locationRegion
        const {
          data: { node: checkout },
        } = await getCheckoutQuery({ countryCode, checkoutId: id })
        return checkout as Promise<CheckoutFragment>
      }
      return false
    } catch (e) {
      console.error((e as Error).message)
    }
  }, [id, keys.market, store.locationRegion, getCheckoutQuery, getStorage])

  const saveCheckout = useCallback(
    checkout => {
      try {
        setCheckout(checkoutNormaliser(checkout))
        setStorage(keys.checkout, checkout?.id)
        setStorage(keys.market, checkout?.buyerIdentity?.countryCode)
      } catch (e) {
        console.error((e as Error).message)
      }
    },
    [setCheckout, checkoutNormaliser, keys, setStorage]
  )

  const refreshCheckout = useCallback(async () => {
    try {
      const checkout = await getCheckout()
      if (checkout === false || checkout === undefined) throw new Error("existingCheckout is false or undefined")

      setCheckout(checkoutNormaliser(checkout))
    } catch (e) {
      console.error((e as Error).message)
    }
  }, [getCheckout, setCheckout, checkoutNormaliser])

  const createCheckout = useCallback(
    async (countryCode = "AU", forceNew = false) => {
      try {
        const existingCheckout = !forceNew && (await getCheckout())

        if (
          forceNew ||
          !existingCheckout ||
          !existingCheckout?.id ||
          existingCheckout?.completedAt !== null ||
          Object.keys(existingCheckout).length < 1
        ) {
          const {
            data: {
              checkoutCreate: { checkout },
            },
          } = await checkoutCreate({
            variables: {
              countryCode,
              input: {
                buyerIdentity: {
                  countryCode,
                },
              },
            },
          })
          if (checkout) saveCheckout(checkout)
        } else {
          saveCheckout(existingCheckout)
        }
      } catch (e) {
        console.error((e as Error).message)
        const isThrottled = (e as Error).message?.toLowerCase()?.includes("throttled")
        if (!isThrottled) removeStorage(keys.checkout)
      }
    },
    [getCheckout, saveCheckout, checkoutCreate, removeStorage, keys]
  )

  const gotoCheckout = useCallback(
    async e => {
      e.preventDefault()
      setLoading(true)

      if (customer?.email) {
        try {
          const response = await callFunction("checkout-multipass", {
            customerEmail: customer?.email,
            checkoutId: checkout?.id,
            webUrl: checkout?.webUrl,
          })

          const url = response.status !== "error" && response.body.includes("https://") ? response.body : checkout?.webUrl

          window.location.href = url
        } catch (e) {
          if (checkout?.webUrl) window.location.href = checkout.webUrl
        }
      } else {
        if (checkout?.webUrl) window.location.href = checkout.webUrl
      }
    },
    [callFunction, checkout, customer]
  )

  const contextValue = React.useMemo<ContextProps>(
    () => ({
      id,
      url,
      count,
      loading,
      checkout,
      countryCode,
      gotoCheckout,
      saveCheckout,
      createCheckout,
      refreshCheckout,
      bundleGroups,
      nonBundleLineItems,
    }),
    [
      id,
      url,
      count,
      loading,
      checkout,
      countryCode,
      gotoCheckout,
      saveCheckout,
      createCheckout,
      refreshCheckout,
      bundleGroups,
      nonBundleLineItems,
    ]
  )

  return <CheckoutContext.Provider value={contextValue}>{children}</CheckoutContext.Provider>
}

export const useCheckoutContext = (): ContextProps => ({ ...React.useContext(CheckoutContext) } as ContextProps)

import React from "react"
import { Box, Text } from "@chakra-ui/react"

import { useCheckoutContext } from "@app/providers/checkout"
import CartItem from "@app/components/Cart/CartItem"
import CartBundleItem from "@app/components/Cart/CartBundleItem"

type Props = {
  empty: string
  remove: string
}
const CartList: React.FC<Props> = ({ empty, remove }) => {
  const { count, bundleGroups, nonBundleLineItems } = useCheckoutContext()

  return (
    <Box>
      {count > 0 ? (
        <>
          {nonBundleLineItems?.map(item => (
            <CartItem key={item?.id} item={item} remove={remove} />
          ))}

          {bundleGroups?.map(group => {
            const bundleId = group[0].customAttributes.find(a => a.key === "_bundleId")?.value
            if (!bundleId) return

            return <CartBundleItem key={bundleId} group={group} remove={remove} />
          })}
        </>
      ) : (
        <Text size="paragraphLarge">{empty}</Text>
      )}
    </Box>
  )
}

export default React.memo(CartList)

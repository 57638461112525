import { useState, useEffect, useCallback, useMemo } from "react"
import { useQuery, useLazyQuery, useMutation, useApolloClient, ApolloError } from "@apollo/client"

import { useCore } from "@app/hooks/useCore"
import { useShop } from "@app/hooks/useShop"
import { useAppContext } from "@app/providers/app"
import { useAnalytics } from "@app/hooks/useAnalytics"
import { useConfigContext } from "@app/providers/config"
import { useCheckoutContext } from "@app/providers/checkout"
import { useCustomerContext } from "@app/providers/customer"
import { ProductConnection, ProductVariant } from "shopify-storefront-api-typings"
import { CheckoutFragment } from "@root/types/custom-types/Fragments/CheckoutFragment/CheckoutFragment"
import { NormalisedCheckoutLineItem } from "@root/types/custom-types/Checkout/CheckoutLineItem"
import { NormalisedDiscountApplication } from "@root/types/custom-types/DiscountApplication/DiscountApplication"
import { NormalisedCheckout } from "@root/types/custom-types/Checkout/Checkout"
import { NormalisedCollection } from "@root/types/custom-types/Collection/Collection"
import { NormalisedProduct } from "@root/types/custom-types/Product/Product"
import { Override } from "@root/types/custom-types/utility"

import { GET_COLLECTION_PAGINATED_PRODUCT_COMPLETE } from "@app/graphql/queries/collection"

export type NormalisedImage = {
  alt: string
  src: string
  srcSet?: string
}

export type NormalisedModel = {
  alt: string
  preview: NormalisedImage
  srcSet: string
}

export type NormalisedVariant = ProductVariant & {
  image: NormalisedImage
}

export type NormalisedStaticVariant = ProductVariant & {
  price: {
    amount?: string
    currencyCode?: string
  }
  compareAtPrice: {
    amount?: string
    currencyCode?: string
  }
  priceV2: {
    amount?: string
    currencyCode?: string
  }
  compareAtPriceV2: {
    amount?: string
    currencyCode?: string
  }
}

export type ProductTemplateSanityProduct = {
  metadata?: GatsbyTypes.SanityProduct["_rawMetadata"]
  siblings?: GatsbyTypes.SanityProduct["_rawSiblings"]
  related?: GatsbyTypes.SanityProduct["_rawRelated"]
} & GatsbyTypes.SanityProductFragmentFragment

export type NormalisedShopifyProduct = Override<
  NormalisedProduct,
  {
    variants: NormalisedStaticVariant[]
  }
>

export type CollectionResult = {
  description: string
  descriptionHtml: string
  handle: string
  id: string
  image?: {
    alt: string
    src: string
    srcSet: string
  }
  pageInfo: {
    hasPreviousPage: boolean
    hasNextPage: boolean
    startCursor: string
    endCursor: string
  }
  products: ProductConnection
  title: string
  __typename: string
}

export const useShopify = () => {
  const client = useApolloClient()
  const { shop } = useShop()
  const { customer } = useCustomerContext()
  const {
    settings: { routes },
  } = useConfigContext()
  const { checkout, countryCode } = useCheckoutContext()
  const {
    graphql: {
      queries: { GET_COLLECTION_PRODUCT_COMPLETE, GET_PRODUCTS_BY_HANDLE, GET_COLLECTIONS_BY_HANDLE },
    },
    helpers: { edgeNormaliser, encodeShopifyId },
  } = useCore()

  const formatErrors = (errors: any) => {
    const formatted = errors?.message
      ? { message: errors?.message }
      : //@ts-ignore
        Object.assign(...errors.map((value: any) => ({ [value?.field?.[1]]: { code: value?.code, message: value?.message } })))
    return formatted
  }

  const formatMoney = useCallback(
    (amount: number, currency = "AUD") =>
      new Intl.NumberFormat(`en-${shop?.primaryDomain?.localization?.country || "AU"}`, {
        style: "currency",
        currency: currency,
      }).format(amount),
    [shop?.primaryDomain?.localization?.country]
  )

  const formatDate = (date: string) =>
    new Intl.DateTimeFormat(`en-${shop?.primaryDomain?.localization?.country || "AU"}`, {
      timeZone: "Australia/Melbourne",
    }).format(new Date(date))

  const imageUrl = (src: string, size: string | number): any => {
    const dimensions = `${size}x${size}`
    const match = typeof src === "string" ? src?.match(/\.(jpg|jpeg|gif|png|bmp|bitmap|tiff|tif)(\?v=\d+)?$/i) : false
    return match && src?.includes(`shopify.com`) && size && size !== "master"
      ? `${src?.split(match[0])[0]}_${dimensions}${match[0]}`.replace(/http(s)?:/, "")
      : src
  }

  const imageSrcSets = (src: string, size: string | number) =>
    src.toString()?.includes(`shopify.com`)
      ? [1, 500, 1000, 1500, 2000]
          .filter(set => !size || (size && size >= set))
          .map(set => `${imageUrl(src, set)} ${set}w`)
          .join(`,`)
      : undefined

  const onSale = (price: string, compareAtPrice: string) => compareAtPrice && price && parseInt(compareAtPrice) > parseInt(price)

  const getHandle = (item: any): string => item?.handle || item?.shopify?.handle || ""

  const getFirstAvailableVariant = useCallback(
    (product: NormalisedProduct) =>
      product.variants.find(v => {
        const hasQty = v?.quantityAvailable === undefined || (v?.quantityAvailable && v?.quantityAvailable > 0)
        return (v.availableForSale && hasQty) || v.currentlyNotInStock
      }),
    []
  )

  const getLowestPricedAvailableVariant = useCallback((product: NormalisedProduct) => {
    return product.variants.reduce((lowestPricedVariant, currentVariant) => {
      const hasQty =
        currentVariant.quantityAvailable === undefined || (!!currentVariant.quantityAvailable && currentVariant.quantityAvailable > 0)
      const isAvailable = currentVariant.availableForSale && hasQty
      return isAvailable && currentVariant.priceV2.amount < lowestPricedVariant.priceV2.amount ? currentVariant : lowestPricedVariant
    })
  }, [])

  // TODO: Unify with above function
  const getLowestPricedAvailableVariantV2 = useCallback((product: NormalisedShopifyProduct) => {
    return product.variants.reduce((lowestPricedVariant, currentVariant) => {
      const hasQty =
        currentVariant.quantityAvailable === undefined || (!!currentVariant.quantityAvailable && currentVariant.quantityAvailable > 0)
      const isAvailable: boolean = currentVariant.availableForSale && hasQty
      return isAvailable && currentVariant.priceV2.amount < lowestPricedVariant.priceV2.amount ? currentVariant : lowestPricedVariant
    })
  }, [])

  const addressNormaliser = (address: any) => ({
    ...address,
    default: address?.id === customer?.defaultAddress?.id,
  })

  const orderNormaliser = (orders: any) =>
    edgeNormaliser(orders)?.map((order: any) => ({ ...order, lineItems: edgeNormaliser(order?.lineItems) }))

  const imageNormaliser = (image: any, size: string | number = ""): NormalisedImage => ({
    alt: image?.altText || image?.alt || image?.asset?.alt || "",
    src: imageUrl(image?.originalSrc || image?.src || image?.asset?.url || image || "", size),
    srcSet: imageSrcSets(image?.originalSrc || image?.src || image?.asset?.url || image || "", size),
  })

  const videoNormaliser = (video: any, size: string | number = "") => ({
    alt: video?.alt || "",
    preview: imageNormaliser(video?.previewImage || "", size),
    src: video?.sources
      ?.filter(({ format }: { format: string }) => format === "mp4")
      ?.sort((a: any, b: any) => (a.height < b.height ? 1 : -1))?.[0],
  })

  const modelNormaliser = (model: any, size: string | number = "") => ({
    alt: model?.alt || "",
    preview: imageNormaliser(model?.previewImage || "", size),
    sources: model?.sources,
  })

  const priceNormaliser = (presentmentPrices: any, field = "") =>
    Object.assign(
      {},
      ...edgeNormaliser(presentmentPrices)
        .filter(
          (item: any) => (field && item?.[field] ? item[field] : item)?.currencyCode === (checkout?.currencyCode || shop?.currencyCode)
        )
        .map((item: any) => priceFieldNormaliser(item, field))
    )

  const priceFieldNormaliser = (item: any, field = "") => {
    const price = field && item?.[field] ? item[field] : item
    return {
      amount: `${price?.amount}`,
      local: formatMoney(price?.amount),
      afterpay: formatMoney(price?.amount / 4),
      currencyCode: `${price?.currencyCode || price?.currency_code}`,
    }
  }

  const productNormaliser = (product: any): NormalisedProduct => ({
    ...product,
    collections:
      edgeNormaliser(product?.collections)?.map((collection: any) => ({
        ...collection,
        image: imageNormaliser(collection?.image),
      })) || [],
    images: product?.images
      ? edgeNormaliser(product?.images)?.length > 0
        ? edgeNormaliser(product?.images)?.map((image: any) => imageNormaliser(image))
        : edgeNormaliser(product?.media)
            ?.filter((media: any) => media?.mediaContentType === "IMAGE")
            ?.map((media: any) => imageNormaliser(media?.image))
      : [],
    media: edgeNormaliser(product?.media)?.map((media: any) =>
      media?.mediaContentType === "VIDEO"
        ? videoNormaliser(media)
        : media?.mediaContentType === "IMAGE"
        ? imageNormaliser(media?.image)
        : null
    ),
    metafields: edgeNormaliser(product?.metafields),
    models: edgeNormaliser(product?.media)
      ?.filter((media: any) => media?.mediaContentType === "MODEL_3D")
      ?.map((media: any) => modelNormaliser(media)),
    variants: variantsNormaliser(product?.variants) || [],
    videos: edgeNormaliser(product?.media)
      ?.filter((media: any) => media?.mediaContentType === "VIDEO" || media?.mediaContentType === "EXTERNAL_VIDEO")
      ?.map((media: any) => videoNormaliser(media)),
  })

  const variantsNormaliser = (variants: any) =>
    edgeNormaliser(variants)
      ?.map(variantNormaliser)
      .filter((v: NormalisedVariant | Record<string, never>) => "id" in v)

  const variantNormaliser = (variant: any) => ({
    ...variant,
    ...(variant?.image && { image: imageNormaliser(variant?.image) }),
    ...(variant?.metafields && { metafields: edgeNormaliser(variant?.metafields) }),
  })

  const staticVariantNormaliser = (variant: any) => ({
    ...variant,
    price: {
      amount: variant?.priceV2,
      currencyCode: shop?.currencyCode,
    },
    compareAtPrice: {
      amount: variant?.compareAtPriceV2,
      currencyCode: shop?.currencyCode,
    },
    priceV2: {
      amount: variant?.priceV2,
      currencyCode: shop?.currencyCode,
    },
    compareAtPriceV2: {
      amount: variant?.compareAtPriceV2,
      currencyCode: shop?.currencyCode,
    },
  })

  const adminProductNormaliser = (product: any, config?: any) => ({
    ...product,
    url: routes.PRODUCT,
    availableForSale: product?.variants?.filter(({ available }: { available: boolean }) => available)?.length > 0,
    id: encodeShopifyId(product?.id, "Product"),
    images: product?.images?.map((image: any) => imageNormaliser(image, config?.imageSize || false)) || [],
    legacyId: product?.id,
    productType: product?.product_type,
    priceRange: {
      minVariantPrice: adminPriceNormaliser(product?.presentment_price_ranges?.min_variant_price, "price"),
      maxVariantPrice: adminPriceNormaliser(product?.presentment_price_ranges?.max_variant_price, "price"),
    },
    variants: product?.variants?.map((variant: any) => ({
      ...variant,
      availableForSale: variant?.available,
      id: encodeShopifyId(variant?.id, "ProductVariant"),
      legacyId: variant?.id,
      priceV2: priceNormaliser(adminPresentmentPriceNormaliser(variant?.presentment_prices), "price"),
      compareAtPriceV2: priceNormaliser(adminPresentmentPriceNormaliser(variant?.presentment_prices), "compareAtPrice"),
    })),
  })

  const adminPriceNormaliser = (presentmentPrices: any, field = "") =>
    Object.assign(
      {},
      ...presentmentPrices
        .filter(
          (item: any) => (field && item?.[field] ? item[field] : item)?.currency_code === (checkout?.currencyCode || shop?.currencyCode)
        )
        .map((item: any) => priceFieldNormaliser(item, field))
    )

  const adminPresentmentPriceNormaliser = (presentment_prices: any) =>
    presentment_prices?.map((presentmentPrice: any) => ({
      compareAtPrice: {
        amount: presentmentPrice?.compare_at_price?.amount,
        currencyCode: presentmentPrice?.compare_at_price?.currency_code,
      },
      price: {
        amount: presentmentPrice?.price?.amount,
        currencyCode: presentmentPrice?.price?.currency_code,
      },
    }))

  const collectionNormaliser = (collection: any): NormalisedCollection => ({
    ...collection,
    id: parseInt(collection?.shopify?.id) || collection?.id,
    handle: collection?.shopify?.handle || collection?.handle,
    image: imageNormaliser(collection?.image),
    ...(collection?.metafields && { metafields: edgeNormaliser(collection?.metafields) }),
    pageInfo: collection?.products?.pageInfo || null,
    products: collection?.products?.edges?.length ? edgeNormaliser(collection?.products).map(productNormaliser) : [],
  })

  const checkoutNormaliser = (checkout: CheckoutFragment): NormalisedCheckout => ({
    ...checkout,
    lineItems: (edgeNormaliser(checkout.lineItems) as NormalisedCheckoutLineItem[]) || [],
    discountApplications: edgeNormaliser(checkout.discountApplications) as NormalisedDiscountApplication[],
  })

  const getCollection = async ({
    firstCollections = 0,
    firstImages = 0,
    firstMedia = 0,
    firstMetafields = 0,
    firstProducts = 0,
    firstVariants = 0,
    handle = "",
  }): Promise<NormalisedCollection> => {
    const { data } = await client.query({
      query: GET_COLLECTION_PRODUCT_COMPLETE,
      variables: {
        countryCode,
        handle,
        firstCollections,
        firstImages,
        firstMedia,
        firstMetafields,
        firstProducts,
        firstVariants,
        metafieldIdentifiers: [],
      },
    })

    return collectionNormaliser(data?.collection)
  }

  const getCollections = async ({
    countryCode = "AU",
    handles = [""],
    firstCollections = 0,
    firstImages = 0,
    firstMedia = 0,
    firstMetafields = 0,
    firstVariants = 0,
    firstProducts = 0,
  }): Promise<NormalisedCollection[]> => {
    const { data } = await client.query({
      query: GET_COLLECTIONS_BY_HANDLE(handles),
      variables: {
        countryCode,
        firstCollections,
        firstImages,
        firstMedia,
        firstMetafields,
        firstVariants,
        firstProducts,
        metafieldIdentifiers: [],
      },
    })

    //@ts-ignore
    return handles?.map(handle => collectionNormaliser(data[`product${handle?.replace(/-/g, "")}`]))
  }

  const getProducts = async ({
    countryCode = "AU",
    handles = [""],
    firstCollections = 0,
    firstImages = 0,
    firstMedia = 0,
    firstMetafields = 0,
    firstVariants = 0,
  }) => {
    const { data } = await client.query({
      query: GET_PRODUCTS_BY_HANDLE(handles),
      variables: {
        countryCode,
        firstCollections,
        firstImages,
        firstMedia,
        firstMetafields,
        firstVariants,
        metafieldIdentifiers: [],
      },
    })

    //@ts-ignore
    return handles
      ?.map(handle => {
        const productData = data[`product${handle?.replace(/-/g, "")}`]
        if (!productData) return
        return productNormaliser(productData)
      })
      .filter(p => p !== undefined) as NormalisedProduct[]
  }

  return {
    client,
    useQuery,
    useLazyQuery,
    useMutation,
    formatErrors,
    onSale,
    imageUrl,
    imageSrcSets,
    formatDate,
    formatMoney,
    imageNormaliser,
    checkoutNormaliser,
    orderNormaliser,
    addressNormaliser,
    productNormaliser,
    variantNormaliser,
    variantsNormaliser,
    staticVariantNormaliser,
    collectionNormaliser,
    adminProductNormaliser,
    getHandle,
    getCollection,
    getCollections,
    getProducts,
    getFirstAvailableVariant,
    getLowestPricedAvailableVariant,
    getLowestPricedAvailableVariantV2,
  }
}

export const useShopifyCollection = ({
  handle = "",
  pageSize = 10,
  ...config
}: {
  firstImages?: number
  firstMedia?: number
  firstVariants?: number
  handle: string
  pageSize: number
  before?: string
  after?: string
}) => {
  const client = useApolloClient()
  const { countryCode } = useCheckoutContext()
  const { collectionNormaliser } = useShopify()

  const [after, setAfter] = useState<string | null>(config.after || null)
  const [before, setBefore] = useState<string | null>(config.before || null)
  const [isLoading, setLoading] = useState<boolean>(false)
  const [collection, setCollection] = useState<NormalisedCollection | null>(null)
  const [page, setPage] = useState(1)

  const { firstImages = 1, firstMedia = 0, firstVariants = 1 } = config
  const hasPreviousPage = collection?.pageInfo?.hasPreviousPage || false
  const hasNextPage = collection?.pageInfo?.hasNextPage || false

  const getCollectionPaginated = useCallback(
    async ({
      after = null,
      before = null,
      firstCollections = 0,
      firstImages = 0,
      firstMedia = 0,
      firstMetafields = 0,
      firstProducts = 0,
      lastProducts = 0,
      firstVariants = 0,
      handle = "",
    }) => {
      setLoading(true)
      const { data } = await client.query({
        query: GET_COLLECTION_PAGINATED_PRODUCT_COMPLETE,
        variables: {
          after,
          before,
          countryCode,
          handle,
          firstCollections,
          firstImages,
          firstMedia,
          firstMetafields,
          firstProducts,
          lastProducts,
          firstVariants,
          metafieldIdentifiers: [],
        },
      })
      setLoading(false)

      return collectionNormaliser(data?.collection)
    },
    [client, collectionNormaliser, countryCode]
  )

  const fetchItems = useMemo(
    () => async () => {
      const collection = await getCollectionPaginated({
        after,
        before,
        firstProducts: !before ? pageSize : null,
        lastProducts: before ? pageSize : null,
        firstImages,
        firstMedia,
        firstVariants,
        handle,
      })

      if (collection) setCollection(collection)
    },
    [after, before, firstImages, firstMedia, firstVariants, pageSize, handle, getCollectionPaginated, setCollection]
  )

  const getPreviousPage = useCallback(() => {
    if (collection?.pageInfo?.hasPreviousPage) {
      setPage(page => page - 1)
      setAfter(null)
      setBefore(collection?.pageInfo?.startCursor || "")
    }
  }, [collection?.pageInfo?.startCursor, collection?.pageInfo?.hasPreviousPage, setAfter])

  const getNextPage = useCallback(() => {
    if (collection?.pageInfo?.hasNextPage) {
      setPage(page => page + 1)
      setAfter(collection?.pageInfo?.endCursor || "")
      setBefore(null)
    }
  }, [collection?.pageInfo?.endCursor, collection?.pageInfo?.hasNextPage, setAfter, setBefore])

  useEffect(() => {
    if (page > 1) fetchItems()
    // Intentionally only run at selected times
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [after, before, page])

  return { collection, getNextPage, getPreviousPage, hasPreviousPage, hasNextPage, isLoading, page, setPage }
}

export const useShopifyProduct = () => {
  const { activeProduct, setActiveProduct } = useAppContext()
  const { trackProductView } = useAnalytics()

  const selectProduct = useCallback(
    (product, path) => {
      if (path?.includes("products") && product) {
        let currentProduct = product

        try {
          currentProduct = product?.shopify?.raw ? JSON.parse(product?.shopify?.raw) : product
        } catch (e) {
          console.error((e as Error).message)
        }

        if (!activeProduct || activeProduct.id !== currentProduct.id) {
          setActiveProduct(currentProduct)
          trackProductView(currentProduct, currentProduct.variants[0], false)
        }
      } else {
        if (activeProduct !== false) setActiveProduct(false)
      }
    },
    [activeProduct, setActiveProduct, trackProductView]
  )

  const getProductTagValue = useCallback(
    (prefix: string, tags: string[]) => tags.find(tag => tag.toLowerCase().includes(prefix.toLowerCase()))?.split(prefix)[1],
    []
  )

  const getProductTagsValues = useCallback(
    (prefix: string, tags: string[]) =>
      tags
        .map(tag => {
          if (!tag.toLowerCase().includes(prefix.toLowerCase())) return
          return tag.split(prefix)[1]
        })
        .filter(s => s) as string[],
    []
  )

  return { activeProduct, selectProduct, getProductTagValue, getProductTagsValues }
}

export const useShopifyProductRaw = (product: any): { product: NormalisedShopifyProduct } => {
  const { staticVariantNormaliser, productNormaliser } = useShopify()
  try {
    const rawProduct = product?.shopify?.raw ? JSON.parse(product?.shopify?.raw) : product
    const normalisedProduct = productNormaliser(rawProduct)
    return {
      product: {
        ...normalisedProduct,
        variants: rawProduct.variants.map(staticVariantNormaliser),
      },
    }
  } catch (err) {
    console.error("Error parsing raw shopify product")
    const normalisedProduct = productNormaliser(product)
    return { product: { ...normalisedProduct, variants: [] } }
  }
}

type UseShopifyProductLiveReturn = { product: NormalisedShopifyProduct; loading: boolean; error: ApolloError | undefined }
export const useShopifyProductLive = (product: NormalisedShopifyProduct): UseShopifyProductLiveReturn => {
  const {
    graphql: {
      queries: { GET_PRODUCT_AVAILABILITY },
    },
  } = useCore()
  const {
    store: { locationRegion },
  } = useConfigContext()
  const { countryCode } = useCheckoutContext()
  const { variantsNormaliser } = useShopify()

  const [getProduct, { data, called, loading, error }] = useLazyQuery(GET_PRODUCT_AVAILABILITY, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    variables: { countryCode: countryCode || locationRegion, handle: product.handle },
  })

  if (error) console.error(error)

  useEffect(() => {
    if (product.handle && countryCode && !called) getProduct()
  }, [product.handle, called, countryCode, getProduct])

  let liveProduct = undefined
  try {
    liveProduct = data && {
      ...product,
      variants: product.variants.map((variant: any, index: number) => ({
        ...variant,
        ...variantsNormaliser(data.product.variants)[index],
      })),
    }
    return { product: liveProduct, loading, error }
  } catch (err) {
    console.error("Error parsing live shopify product")
    return { product, loading, error }
  }
}

export const useShopifyProductRecent = (product: any) => {
  const {
    graphql: {
      queries: { GET_PRODUCT_AVAILABILITY },
    },
  } = useCore()
  const {
    store: { locationRegion },
  } = useConfigContext()
  const { countryCode } = useCheckoutContext()
  const { variantsNormaliser } = useShopify()

  const [getProduct, { data, called, loading, error }] = useLazyQuery(GET_PRODUCT_AVAILABILITY, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    variables: { countryCode: countryCode || locationRegion, handle: product.handle },
  })

  if (error) console.error(error)

  useEffect(() => {
    if (product.handle && countryCode && !called) getProduct()
  }, [product.handle, called, countryCode, getProduct])

  let liveProduct = undefined
  try {
    liveProduct = data && {
      ...product,
      variants: product.variants.map((variant: any, index: number) => ({
        ...variant,
        ...variantsNormaliser(data.product.variants)[index],
      })),
    }
    return { product: liveProduct, loading, error }
  } catch (err) {
    console.error("Error parsing live shopify product")
    return { product, loading, error }
  }
}

export const useShopifyVariants = ({ firstAvailable = true, useParameter = false, loading = false, product }) => {
  const {
    helpers: { encodeShopifyId, decodeShopifyId, getUrlParameter, isBrowser, setUrlParameter },
  } = useCore()
  const { activeVariant, activeProduct, setActiveVariant } = useAppContext()
  const { checkout } = useCheckoutContext()
  const { shop } = useShop()
  const {
    settings: { params },
  } = useConfigContext()
  const { trackProductView } = useAnalytics()
  const { id, variants } = product || {}

  const currentVariant = getUrlParameter(params?.variant)
  const defaultVariant =
    (useParameter && variants?.find(({ id }: { id: string }) => id === encodeShopifyId(currentVariant, "ProductVariant"))) ||
    (firstAvailable && variants?.find(({ availableForSale }: { availableForSale: boolean }) => availableForSale))

  const [selectedOptions, setSelectedOptions] = useState(defaultVariant?.selectedOptions || [])

  const handleOptions = useCallback(
    option => {
      setSelectedOptions(selectedOptions?.map((selectedOption: any) => (selectedOption.name === option.name ? option : selectedOption)))
    },
    [selectedOptions, setSelectedOptions]
  )

  useEffect(() => {
    if (
      (checkout?.currencyCode || shop?.currencyCode) &&
      !loading &&
      activeVariant &&
      !activeVariant?.priceV2?.amount &&
      defaultVariant?.priceV2?.amount
    ) {
      setSelectedOptions(defaultVariant?.selectedOptions)
    }
    // Intentionally only run at selected times
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkout?.currencyCode, id, loading, shop?.currencyCode, variants?.length, defaultVariant?.selectedOptions])

  // intentionally exclude variants from dependencies
  useEffect(() => {
    if (useParameter) {
      setActiveVariant(
        variants?.find(
          ({ selectedOptions: variantOptions }: { selectedOptions: any }) =>
            variantOptions?.filter(
              (variantOption: any) =>
                variantOption.value === selectedOptions.find((selectedOption: any) => selectedOption.name === variantOption.name)?.value
            )?.length === selectedOptions?.length
        ) || null
      )
    } else {
      setActiveVariant(
        variants?.find(
          ({ selectedOptions: variantOptions }: { selectedOptions: any }) =>
            variantOptions?.filter(
              (variantOption: any) =>
                variantOption.value === selectedOptions.find((selectedOption: any) => selectedOption.name === variantOption.name)?.value
            )?.length === selectedOptions?.length
        ) || null
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOptions])

  useEffect(() => {
    if (useParameter && activeVariant?.id && !loading && isBrowser) {
      if (currentVariant !== encodeShopifyId(activeVariant.id, "ProductVariant") && activeVariant.id !== defaultVariant.id) {
        window.history.replaceState(
          null,
          window.document.title,
          setUrlParameter(params.variant, decodeShopifyId(activeVariant.id, "ProductVariant"))
        )
      }

      if (checkout?.currencyCode && activeVariant?.id && activeVariant?.priceV2?.amount && !loading)
        trackProductView(activeProduct, activeVariant, false)
    }
    // Intentionally only run at selected times
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeVariant?.id, activeVariant?.priceV2?.amount, checkout?.currencyCode, loading])

  return { activeVariant, handleOptions, selectedOptions }
}

export const useShopifyPrice = (variant: any) => {
  const { checkout } = useCheckoutContext()
  const { formatMoney } = useShopify()

  return useMemo(() => {
    if (!variant || !checkout?.currencyCode) {
      return {
        onSale: false,
        price: undefined,
        currencyCode: "AUD",
        formattedPrice: undefined,
        compareAtPrice: undefined,
        formattedCompareAtPrice: undefined,
      }
    }

    const currencyCode = checkout?.currencyCode ?? "AUD"
    const price = variant?.priceV2?.amount ? Number(variant?.priceV2?.amount) : 0
    const compareAtPrice = variant?.compareAtPriceV2?.amount ? Number(variant?.compareAtPriceV2?.amount) : 0
    const onSale = !!compareAtPrice && !!price && compareAtPrice > price
    const formattedPrice = formatMoney(price, currencyCode)
    const formattedCompareAtPrice = compareAtPrice !== 0 ? formatMoney(compareAtPrice, currencyCode) : undefined

    return {
      price,
      onSale,
      currencyCode,
      compareAtPrice,
      formattedPrice,
      formattedCompareAtPrice,
    }
  }, [variant, checkout?.currencyCode, formatMoney])
}

export const useShopifyPriceRange = (product: any, separator = " to ") => {
  const { checkout } = useCheckoutContext()
  const { formatMoney } = useShopify()
  const {
    helpers: { formatPrice },
  } = useCore()

  return useMemo(() => {
    if (!product) {
      return {
        priceMin: undefined,
        priceMax: undefined,
        currencyCode: "AUD",
        formattedPriceRange: undefined,
      }
    }

    const currencyCode = checkout?.currencyCode ?? "AUD"
    const priceRangeMin = product?.priceRange?.minVariantPrice || 0
    const priceRangeMax = product?.priceRange?.maxVariantPrice || 0
    const priceMin = priceRangeMin?.amount ? Number(priceRangeMin?.amount) : 0
    const priceMax = priceRangeMax?.amount ? Number(priceRangeMax?.amount) : 0
    const formattedPriceRange =
      priceMin < priceMax && priceMin > 0
        ? `${formatMoney(priceMin, currencyCode)}${separator}${formatPrice(`${priceMax}`)}`
        : formatMoney(priceMax, currencyCode)
    const formattedMinPrice =
      priceMin < priceMax && priceMin > 0 ? `from ${formatMoney(priceMin, currencyCode)}` : formatMoney(priceMax, currencyCode)

    return {
      priceMin,
      priceMax,
      currencyCode,
      formattedPriceRange,
      formattedMinPrice,
    }
  }, [product, checkout?.currencyCode, separator, formatMoney, formatPrice])
}

import React from "react"
import { Container, Flex, Box, Text, Hide, BoxProps, keyframes } from "@chakra-ui/react"
import { useAnnouncement } from "@app/hooks/useAnnouncement"
import Link from "@app/components/Link"

const Announcement: React.FC<BoxProps> = props => {
  const { items } = useAnnouncement()
  const marqeeKeyframes = keyframes`
    0%{ transform: translate3d(0, 0, 0) }
    100%{ transform: translate3d(-50%, 0, 0) }
  `
  const marqueeAnimation = `${marqeeKeyframes} 10s linear infinite`

  return (
    <Box as="section" bg="brand.gradient" {...props}>
      <Container maxW={"container.4xl"} overflow="hidden" px="0">
        <Flex
          justifyContent="center"
          color="background.white"
          width={{ base: "fit-content", md: "100%" }}
          py={{ base: "2.5", lg: "3" }}
          animation={{ base: marqueeAnimation, md: "none" }}
        >
          {items && !!items.length
            ? items.map((item, index) => <AnnouncementItem key={index} item={item} index={index} itemsLength={items.length} />)
            : null}
          <Hide above="md">
            {items && !!items.length
              ? items.map((item, index) => <AnnouncementItem key={index} item={item} index={index} itemsLength={items.length} />)
              : null}
          </Hide>
        </Flex>
      </Container>
    </Box>
  )
}

type AnnouncementItemProps = {
  item: GatsbyTypes.Maybe<
    Pick<GatsbyTypes.SanityAnnouncement, "title"> & {
      readonly link: GatsbyTypes.Maybe<Pick<GatsbyTypes.SanityLink, "link" | "external">>
    }
  >
  index: number
  itemsLength: number
}
const AnnouncementItem: React.FC<AnnouncementItemProps> = ({ item, index, itemsLength }) => {
  const isLast = index + 1 === itemsLength

  return item ? (
    item?.link?.link ? (
      <Text
        as={Link}
        to={item.link.link}
        size="smSemiSmall"
        px={{ base: "4", lg: 8 }}
        whiteSpace="nowrap"
        borderRight={{ base: "1px solid", md: !isLast ? "2px solid" : "none" }}
        borderColor="background.white"
      >
        {item.title}
      </Text>
    ) : (
      <Text
        size="smSemiSmall"
        px={{ base: "4", lg: 8 }}
        whiteSpace="nowrap"
        borderRight={{ base: "1px solid", md: !isLast ? "2px solid" : "none" }}
        borderColor="background.white"
      >
        {item.title}
      </Text>
    )
  ) : null
}

export default React.memo(Announcement)

import React, { useState, useEffect } from "react"

import { useStorage } from "@app/hooks/useCore"
import { useAppContext } from "@app/providers/app"
import { useConfigContext } from "@app/providers/config"

type ContextProps = {
  recent: Array<string>
}

export const ProductsContext = React.createContext<ContextProps | undefined>(undefined)

export const ProductsProvider: React.FC = ({ children }) => {
  const { activeProduct } = useAppContext()
  const {
    settings: { keys },
  } = useConfigContext()
  const { getStorage, setStorage } = useStorage()

  const saved = getStorage(keys.recent) || []
  const [recent, setRecent] = useState<Array<string>>(saved)

  useEffect(() => {
    if (activeProduct?.handle) {
      // store a maximum of 12 saved items
      const recentItems = [activeProduct.handle, ...recent.filter(handle => handle !== activeProduct.handle)].slice(0, 12)
      setRecent(recentItems)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeProduct])

  useEffect(() => {
    setStorage(keys.recent, recent)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recent])

  const contextValue = React.useMemo<ContextProps>(
    () => ({
      recent,
    }),
    [recent]
  )

  return <ProductsContext.Provider value={contextValue}>{children}</ProductsContext.Provider>
}

export const useProductsContext = (): ContextProps => ({ ...React.useContext(ProductsContext) } as ContextProps)

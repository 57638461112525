import gql from "graphql-tag"
import { DotHeadless } from "@dotdev/dotheadless-app"

const {
  graphql: {
    fragments: { COLLECTION_FRAGMENT, PRODUCT_FRAGMENT },
  },
} = DotHeadless

export const GET_COLLECTION_PAGINATED_PRODUCT_COMPLETE = gql`
  query (
    $after: String
    $before: String
    $countryCode: CountryCode!
    $handle: String!
    $firstCollections: Int
    $firstImages: Int
    $firstMedia: Int
    $firstMetafields: Int
    $firstProducts: Int
    $lastProducts: Int
    $firstVariants: Int
    $afterVariants: String
  ) @inContext(country: $countryCode) {
    collection: collection(handle: $handle) {
      ...CollectionFragment
      products(first: $firstProducts, last: $lastProducts, before: $before, after: $after) {
        pageInfo {
          hasNextPage
          hasPreviousPage
          endCursor
          startCursor
        }
        edges {
          node {
            ...ProductFragment
          }
        }
      }
    }
  }
  ${COLLECTION_FRAGMENT}
  ${PRODUCT_FRAGMENT}
`
